const currentPath = window.location.pathname.replace(/\/$/, '');
export const updateMenu = () => {
  // sort by length desc to match the more specific paths first
  const menuItems = [...(document as any).querySelectorAll('#navbarNavAltMarkup > ul > li > a')].sort(
    (a1, a2) => a2.getAttribute('href').length - a1.getAttribute('href').length,
  );

  const active = ['active'];

  // reset menu
  menuItems.forEach((i) => i.classList.remove(...active));

  for (const item of menuItems) {
    if (currentPath.startsWith(item.getAttribute('href'))) {
      item.classList.add(...active);
      break;
    }
  }
};
