import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const jobSplitStyle: StyleRulesCallback = (theme: Theme) => ({
  gridDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: theme.spacing.unit,
  }
});

export default jobSplitStyle;
