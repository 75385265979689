import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import companyInfoStyle from './companyInfoStyle';
import { ReducerState } from '@/app/redux/store';
import API from '@/app/api/externalAPIs';
import Service from '@/app/utils/service';
import { throwError } from '@/app/redux/error';

type State = {
  description: string;
};

/**
 * Display any HTMl content, also embed code (videos)
 */
class CompanyInfo extends React.Component<any> {
  state: State = {
    description: null
  };

  componentDidMount () {
    Service.get(
      API.companyDescription(),
      (data: any) => {
        this.setState({ description: data });
      },
      (error: any) => this.props.throwError(error)
    );
  }

  render() {
    const { classes, companyName } = this.props;
    const { description } = this.state;
    return (
      <>
        <Typography
          variant="h5"
          gutterBottom
        >
          {companyName}
        </Typography>
        <Typography
          className={classes.content}
          gutterBottom
          dangerouslySetInnerHTML={{__html: (description)}}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  companyName: state.companyInfo.get('name'),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ throwError }, dispatch);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(companyInfoStyle)
);

export default enhance(CompanyInfo);
