import { StyleRulesCallback } from '@material-ui/core';

const trainingDetailsStyle: StyleRulesCallback = () => {
  return ({
    title: {
      display: 'flex',

      '& > *': {
        flex: 1,
      },
    },
    description: {
      overflowX: 'hidden'
    }
  });
};

export default trainingDetailsStyle;
