import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Jobs from '@/app/pages/Jobs/Jobs';
import Job from '@/app/pages/Job/Job';
import Test from '@/app/pages/Test/Test';
import { HOMEPAGE } from '@/app/utils/helper';
import DocumentApproval from '@/app/pages/DocumentApproval/DocumentApproval';
import Trainings from '@/app/pages/Trainings/Trainings';
import TrainingRegistration from '@/app/pages/TrainingRegistration/TrainingRegistration';

const ExternalRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${HOMEPAGE}/external/jobs`} component={Jobs} />
      <Route exact path={`${HOMEPAGE}/external/jobs/:jobId`} component={Job} />
      <Route exact path={`${HOMEPAGE}/external/document-approval/:entityId`} component={DocumentApproval} />
      <Route exact path={`${HOMEPAGE}/external/test`} component={Test} />
      <Route exact path={`${HOMEPAGE}/external/trainings`} component={Trainings} />
      <Route exact path={`${HOMEPAGE}/external/trainings/:trainingId/register`} component={TrainingRegistration} />
    </Switch>
  );
};

export default ExternalRoutes;