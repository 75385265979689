import {
  Hidden,
  Grid,
  withStyles,
  WithStyles,
  Button,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TrainingsList from '@/app/components/PublicTrainings/TrainingsList/TrainingsList';
import TrainingRegistration from '@/app/pages/TrainingRegistration/TrainingRegistration';
import { HOMEPAGE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import trainingSplitStyle from './trainingSplitStyle';

type Props = WithStyles<typeof trainingSplitStyle> &
  RouteComponentProps<{ trainingId?: string }>;

const TrainingSplit = (props: Props) => {
  const handleRegisterForTraining = (trainingId: string) => {
    props.history.push(`${HOMEPAGE}/isolated/trainings/${trainingId}/register`);
  };

  const renderTrainingRegistrationPage = () => {
    if (!props.match.params.trainingId) {
      return null;
    }

    return (
      <>
        <Hidden mdUp>
          <Button
            onClick={() => {
              props.history.push(`${HOMEPAGE}/isolated/trainings`);
            }}
          >
            <ArrowBack />
            {translate.t('laBack')}
          </Button>
        </Hidden>

        <TrainingRegistration {...props} type="isolated" />
      </>
    );
  };

  return (
    <Grid container direction="row">
      <Hidden smDown={!!props.match.params.trainingId}>
        <Grid item className={props.classes.gridDivider} xs={12} md={4} lg={3}>
          <TrainingsList onRegisterForTraining={handleRegisterForTraining} />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} lg={9}>
        {renderTrainingRegistrationPage()}
      </Grid>
    </Grid>
  );
};

export default withStyles(trainingSplitStyle)(TrainingSplit);
