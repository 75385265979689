import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { RouteComponentProps } from 'react-router-dom';
import { Typography, Grid, Hidden, Divider } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import API from '@/app/api/externalAPIs';
import JobApplyForm from '@/app/components/Jobs/JobApplyForm/JobApplyForm';
import JobDetail from '@/app/components/Jobs/JobDetail/JobDetail';
import { JobType } from '@/app/components/Jobs/JobType';
import { getJobLocation } from '@/app/components/Jobs/JobsList/JobsList';
import {
  ChildInputType,
  InputType,
} from '@/app/components/FormFields/FormFields';
import { icon, container, divider } from '@/app/jss/common';
import { throwError } from '@/app/redux/error';
import { ModulesType } from '@/app/redux/modules';
import { ReducerState } from '@/app/redux/store';
import { CUSTOM_PREFIX, getUrlParam, getFormData } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

type StateType = {
  jobInfo: JobType;
  showSuccess: boolean;
  successData: any;
};

type MapStateToProps = {
  modules: ModulesType;
};

type MapDispatchToProps = {
  throwError: typeof throwError;
};

type Props = MapDispatchToProps &
  MapStateToProps &
  RouteComponentProps<{ jobId: string }>;

class Job extends React.Component<Props, StateType> {
  state: StateType = {
    jobInfo: null,
    showSuccess: false,
    successData: null,
  };

  componentDidMount() {
    this.handleJobDataFetch();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.jobId === this.props.match.params.jobId) {
      return;
    }

    this.handleJobDataFetch();
  }

  shouldComponentUpdate(_nextProps: any, nextState: StateType) {
    return nextState.jobInfo !== null;
  }

  getJobInfo(data: any) {
    const customFieldsData: Array<InputType> = this.getCustomFieldsData(data);
    const jobInfo: JobType = {
      id: data.fJobOpeningId,
      title: data.fJobOpeningTitle,
      openingDate: data.fJobOpeningStartDate,
      endingDate: data.fJobOpeningEndDate ? data.fJobOpeningEndDate : '',
      attachmentRequired: data.fJobOpeningAttachmentIsMandatory,
      description: data.fJobOpeningDesc,
      customFields: customFieldsData,
      isOpen: data.fJobOpeningIsOpen,
      location: {
        city: data.fJobOpeningLocation.fJobOpeningCity,
        countryCode: data.fJobOpeningLocation.fJobOpeningCountry,
        postalCode: data.fJobOpeningLocation.fJobOpeningPostalCode,
      },
    };

    return jobInfo;
  }

  getCustomFieldsData(data: any) {
    const { CUSTOM_DATA } = this.props.modules;
    if (!CUSTOM_DATA || !data) {
      return [];
    }

    const customFieldsData = data.fJobOpeningCF.map((cf: any) => {
      const foundCustomField = data.fJobOpeningCustomFields.find(
        (customField: any) => {
          return customField.name === cf.fCustomDataAttributeName;
        },
      );

      let childrenInputs = cf.fCustomDataAttributeValues.map((av: any) => ({
        label: av.name,
        value: av.code,
      }));

      if (
        cf.fCustomDataAttributeDataType === 'MULTISELECT' ||
        cf.fCustomDataAttributeDataType === 'ENUM'
      ) {
        const sortableInputs = cf.fCustomDataAttributeValues.map((av: any) => ({
          label: av.name,
          value: av.code,
          seq: av.seq || 0,
        }));

        childrenInputs = sortableInputs.sort(
          (a: ChildInputType, b: ChildInputType) => a.seq - b.seq,
        );
      }

      return {
        type: cf.fCustomDataAttributeDataType,
        label: cf.fCustomDataAttributeLabel,
        name: CUSTOM_PREFIX + cf.fCustomDataAttributeName,
        code: cf.fCustomDataAttributeName,
        childrenInputs,
        order: cf.fCustomDataAttributeSeq,
        required: foundCustomField.isRequired,
      };
    });

    return customFieldsData.sort(
      (a: InputType, b: InputType) => a.order - b.order,
    );
  }

  getJobId() {
    return parseInt(this.props.match.params.jobId, 10);
  }

  handleJobDataFetch = () => {
    const jobId = this.getJobId();
    Service.get(
      API.job(jobId),
      (data: any) => {
        const jobInfo = this.getJobInfo(data);
        this.setState({ jobInfo });
      },
      (error: any) => this.props.throwError(error),
    );
  };

  handleSubmit = (values: any) => {
    const jobId = this.getJobId();
    const { formData, customFields } = getFormData(values);
    formData.append('fJobAppCustomFields', JSON.stringify(customFields));
    const source = getUrlParam(this.props, 'source') || 'INTERNAL';
    formData.append('fJobAppSource', source.toString());
    formData.append('fJobAppOpeningFK', jobId.toString());

    // redux form need onsubmit method return a promise
    return Service.postFormData(
      API.jobApply(jobId),
      formData,
      (data: any) => {
        this.setState({ showSuccess: true, successData: data });
      },
      (error: any) => this.props.throwError(error),
    );
  };

  renderSuccess(successData: any) {
    return (
      <>
        <Typography
          style={{ color: 'green', display: 'flex', alignItems: 'center' }}
          variant="h6"
          gutterBottom
          id="job-title"
        >
          <CheckIcon style={icon} /> {translate.t('laThaApp')}
        </Typography>
        <Typography variant="h6">
          {translate.t('title_job_apply_form_received')}
        </Typography>
        <Typography variant="body1" component="div">
          <Grid container>
            {!successData.fJobAppFirstName ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('fJobAppFirstName')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppFirstName}
                </Grid>
              </>
            )}
            {!successData.fJobAppGivenName ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('fJobAppGivenNames')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppGivenNames}
                </Grid>
              </>
            )}
            {!successData.fJobAppLastName ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('fJobAppLastName')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppLastName}
                </Grid>
              </>
            )}
            {!successData.fJobAppEmailAddress ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('fJobAppEmailAddress')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppEmailAddress}
                </Grid>
              </>
            )}
            {!successData.fJobAppPhoneNumber ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('fJobAppPhoneNumber')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppPhoneNumber}
                </Grid>
              </>
            )}
            {!successData.fJobAppAttachments ||
            successData.fJobAppAttachments.length === 0 ? (
              ''
            ) : (
              <>
                <Grid item xs={4}>
                  {translate.t('laAttachments')}:
                </Grid>
                <Grid item xs={8}>
                  {successData.fJobAppAttachments.map(
                    (att: any, index: number) => (
                      <div key={`${index}-${att.fAttachmentName}`}>
                        {att.fAttachmentName}
                      </div>
                    ),
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Typography>
      </>
    );
  }

  render() {
    // if there is no info, do not continue rendering
    if (!this.state.jobInfo) {
      return null;
    }

    // is the application is closed
    if (!this.state.jobInfo.isOpen) {
      return (
        <Typography variant="h6" gutterBottom>
          {translate.t('text_job_app_closed')}
        </Typography>
      );
    }

    const { jobInfo, showSuccess, successData } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} style={container}>
          <JobDetail
            title={jobInfo.title}
            description={jobInfo.description}
            succeed={showSuccess}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={container}>
          <Hidden mdUp>
            <Divider style={divider} />
          </Hidden>

          {showSuccess ? (
            this.renderSuccess(successData)
          ) : (
            <JobApplyForm
              attachmentMandatory={jobInfo.attachmentRequired}
              onSubmit={this.handleSubmit}
              jobId={this.getJobId()}
              customFields={jobInfo.customFields}
              location={getJobLocation(jobInfo)}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  modules: state.modules.get('allModules'),
});

const enhance = compose(connect(mapStateToProps, { throwError }));

export default enhance(Job);
