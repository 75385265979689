import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
  Typography,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import compose from 'recompose/compose';
import Service from '@/app/utils/service';
import API from '@/app/api/externalAPIs';
import { INPUT_TYPE, isValidEmail } from '@/app/utils/helper';
import { RenderUploadField } from '@/app/components/FormFieldBuilders';
import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import translate from '@/app/utils/translate';
import { connect } from 'react-redux';
import { throwError } from '@/app/redux/error';

type StateType = {
  inputs: InputType[],
  policyDocumentId: number,
  fJobAppPolicyAgreed: boolean,
};

interface OuterProps {
  attachmentMandatory: boolean;
  jobId: number;
  customFields: InputType[];
  onSubmit: Function;
  location?: string;
}

interface InnerProps extends OuterProps, InjectedFormProps {
  throwError: any;
}

const ACCEPTED_FILES_EXT_MIME = 'image/*,.pdf,.odt,.docx,.doc,.txt';
const fileTypesAllowed = (fileValues: any, acceptedFilesExtMime: string) => {
  if (!fileValues || !fileValues.length || !acceptedFilesExtMime) {
    return true;
  }

  const extMimeSpecs = acceptedFilesExtMime.split(',');
  const unacceptableFileTypeFound = fileValues.some((file: any) => {
    if (!file.name || !file.name.split('.').length) {
      return true;
    }

    const fileExt = file.name.split('.').reverse()[0];
    const fileMime = file.type;

    const extMatch = extMimeSpecs.some((extMime) => `.${fileExt}`.match(extMime));
    const mimeMatch = extMimeSpecs.some((extMime) => {
      const specificMatch = `${fileMime}`.match(extMime);
      const generalMatch = `${fileMime.split('/')[0]}/*`.match(extMime);

      return specificMatch || generalMatch;
    });

    return !extMatch && !mimeMatch;
  });

  return !unacceptableFileTypeFound;
};

class JobApplyForm extends React.Component<InnerProps> {
  DEFAULT_INPUTS: InputType[] = [
    {
      type: INPUT_TYPE.TEXT,
      code: 'fJobAppFirstName',
      name: 'fJobAppFirstName',
      label: translate.t('laFirstName'),
      order: 1,
      required: true
    },
    {
      type: INPUT_TYPE.TEXT,
      code: 'fJobAppGivenNames',
      name: 'fJobAppGivenNames',
      label: translate.t('laGivenNames'),
      order: 2,
      required: true
    },
    {
      type: INPUT_TYPE.TEXT,
      code: 'fJobAppLastName',
      name: 'fJobAppLastName',
      label: translate.t('laLastName'),
      order: 3,
      required: true
    },
    {
      type: INPUT_TYPE.EMAIL,
      code: 'fJobAppEmailAddress',
      name: 'fJobAppEmailAddress',
      label: translate.t('laEmail'),
      order: 4,
      required: true
    },
    {
      type: INPUT_TYPE.TEL,
      code: 'fJobAppPhoneNumber',
      name: 'fJobAppPhoneNumber',
      label: translate.t('laPhone'),
      order: 5,
      required: true
    },
  ];

  state: StateType = {
    inputs: this.DEFAULT_INPUTS,  // hold inputs values except dates
    policyDocumentId: 0,          // if there is policy document
    fJobAppPolicyAgreed: false,   // if the user has clicked the agree policy
  };

  constructor(props: any) {
    super(props);

    const safeCustomInputs = this.props.customFields.map(input => {
      if (input.type !== INPUT_TYPE.TEXTAREA) {
        return input;
      }

      return {
        ...input,
        limit: 3000,
      };
    });

    this.state.inputs = [...this.DEFAULT_INPUTS, ...safeCustomInputs];
  }

  getPolicyDocument() {
    Service.get(
      API.jobPolicy(this.props.jobId),
      (data: any) => {
        if (data) {
          this.setState({
            policyDocumentId: data.fDocumentId
          });
        }
      },
      (error: any) => this.props.throwError(error)
    );
  }

  componentDidMount() {
    this.getPolicyDocument();
  }

  downloadPolicy(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    Service.redirectTo(API.documentLink(this.state.policyDocumentId));
  }

  render() {
    const { attachmentMandatory, handleSubmit, location, pristine, submitting, valid } = this.props;
    const { inputs, fJobAppPolicyAgreed, policyDocumentId } = this.state;
    const disabled = !valid || (policyDocumentId > 0 && !fJobAppPolicyAgreed) || pristine || submitting;

    return (
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          gutterBottom
          id="job-title"
        >
          {translate.t('laApptoPosi')}
        </Typography>
        {
          location && (
            <Typography
              variant="body1"
              noWrap
              color="textSecondary"
              gutterBottom
            >
              {location}
            </Typography>
          )
        }

        <FormFields inputs={inputs} />

        <Field
          label={translate.t('laAttachments')}
          name="file"
          required={attachmentMandatory}
          component={RenderUploadField}
          multiple
          accept={ACCEPTED_FILES_EXT_MIME}
          helpText={
            <FormHelperText>
              {translate.t('text_job_application_attachments_help')}
            </FormHelperText>
          }
        />

        {policyDocumentId > 0 ? (
          <FormControl
            fullWidth
            margin="normal"
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="fJobAppPolicyAgreed"
                  color="primary"
                  checked={fJobAppPolicyAgreed}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ fJobAppPolicyAgreed: event.target.checked })}
                />
              }
              label={(
                <div>
                  <span>{translate.t('laRegStateCheck')} </span>
                  <a
                    href="#"
                    onClick={(event: React.MouseEvent<HTMLElement>) => this.downloadPolicy(event)}
                  >
                    {translate.t('link_read_terms')}
                  </a>
                </div>
              )}
            />
          </FormControl>
        ) : null}
        <FormControl margin="normal">
          <Button
            fullWidth={false}
            size="small"
            variant="contained"
            color="primary"
            disabled={disabled}
            type="submit"
          >
            {translate.t('laSubmit')}
          </Button>
        </FormControl>
      </form>
    );
  }
}

const getFileListSize = (fileList: File[]) => {
  return fileList.reduce((size, f) => size + f.size, 0);
};

// form validation
const validate = (values: any, props: any) => {
  let errors: { [k: string]: any } = {};
  const requiredCustomFields = props.customFields
    .filter((customField: any) => customField.required)
    .map((customField: any) => customField.name);

  const requiredFields = [
    'fJobAppFirstName',
    'fJobAppGivenNames',
    'fJobAppLastName',
    'fJobAppEmailAddress',
    'fJobAppPhoneNumber',
  ].concat(requiredCustomFields);

  // check all required fields
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = translate.t('laThisRequired');
    }
  });
  if (props.attachmentMandatory && (!values.file || values.file.length === 0)) {
    errors.file = translate.t('laThisRequired');
  }

  // check email
  if (!isValidEmail(values.fJobAppEmailAddress)) {
    errors.fJobAppEmailAddress = translate.t('laValidEmail');
  }

  // check file extensions and mime type
  if (!fileTypesAllowed(values.file, ACCEPTED_FILES_EXT_MIME)) {
    errors.file = translate.t('laExeFilesNotAccepted');
  }

  // check file size limit
  if (values.file && getFileListSize(values.file) > 9 * 1024 * 1024) {
    errors.file = `${translate.t('laErrorMaxAttachmentsSize')} 9MB`;
  }

  return errors;
};

const enhance = compose<InnerProps, OuterProps>(
  connect(null, { throwError }),
  reduxForm({
    form: 'JobApplyForm',
    validate
  }),
);

export default enhance(JobApplyForm);
