import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => {
  return {
    root: {
      marginRight: 2 * theme.spacing.unit,
      marginLeft: 2 * theme.spacing.unit,
      marginBottom: 4 * theme.spacing.unit,
    },
    alert: {
      cursor: 'pointer',
      top: '10',
      left: '0',
      width: '100%',
      height: '50px',
      padding: '10px',
      margin: '10px',
      borderRadius: '5px',
      backgroundColor: '#FEE',
      border: '1px solid #EDD',
      color: '#A66',
    },
  };
};
