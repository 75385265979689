import * as React from 'react';
import { Typography, Button, Hidden, withStyles, WithStyles } from '@material-ui/core';
import { sanitize } from 'dompurify';
import translate from '@/app/utils/translate';
import jobDetailStyle from './jobDetailStyle';

interface OwnProps {
  title: string;
  succeed: boolean;
  description: string;
}

type Props = OwnProps & WithStyles<typeof jobDetailStyle>;

const Job: React.FunctionComponent<Props> = (props) => {
  const { classes, title, succeed, description } = props;
  return (
    <>
      <div style={{display: 'flex'}}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ flex: 1 }}
        >
          {title}

        </Typography>
        {!succeed ?
          <Hidden mdUp>
            <div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                href="#job-title"
              >
                {translate.t('laApply')}
              </Button>
            </div>
          </Hidden>
          : null}

      </div>
      <Typography
        gutterBottom
        className={classes.description}
        dangerouslySetInnerHTML={{__html: sanitize(description)}}
      />
    </>
  );
};

export default withStyles(jobDetailStyle)(Job);
