import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Hidden,
  Grid,
  withStyles,
  WithStyles,
  Button,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import JobsList from '@/app/components/Jobs/JobsList/JobsList';
import Job from '@/app/pages/Job/Job';
import { HOMEPAGE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import jobSplitStyle from './jobSplitStyle';

type Props = WithStyles<typeof jobSplitStyle> &
  RouteComponentProps<{ jobId?: string }>;

const JobSplit = (props: Props) => {
  const { classes, history } = props;
  const { jobId } = props.match.params;

  const renderJobPage = () => {
    if (!jobId) {
      return <></>;
    }

    return (
      <>
        <Hidden mdUp>
          <Button
            onClick={() => {
              history.push(`${HOMEPAGE}/isolated/jobs`);
            }}
          >
            <ArrowBack />
            {translate.t('laBack')}
          </Button>
        </Hidden>
        <Job {...props} />
      </>
    );
  };

  return (
    <Grid container direction="row">
      <Hidden smDown={!!jobId}>
        <Grid item className={classes.gridDivider} xs={12} md={4} lg={3}>
          <JobsList {...props} isIsolated={true} />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} lg={9}>
        {renderJobPage()}
      </Grid>
    </Grid>
  );
};

export default withStyles(jobSplitStyle)(JobSplit);
