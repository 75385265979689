export const getTrainingName = (training: any, internalTrainingTypes: any[], externalTrainingTypes: any[]) => {
  const trainingTypesMap = {
    INTERNAL_TRAINING_TYPE: internalTrainingTypes,
    EXTERNAL_TRAINING_TYPE: externalTrainingTypes,
  };

  const trainingTypes = trainingTypesMap[training.trainingGroup] || [];
  const trainingType = trainingTypes.find((item: any) => item.code === training.trainingType);

  return trainingType ? trainingType.name : training.trainingType;
};