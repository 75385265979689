import { Divider, Grid, Hidden } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import externalAPIs from '@/app/api/externalAPIs';
import internalAPIs from '@/app/api/internalAPIs';
import TrainingDetails from '@/app/components/PublicTrainings/TrainingDetails/TrainingDetails';
import TrainingRegisterForm from '@/app/components/PublicTrainings/TrainingRegisterForm/TrainingRegisterForm';
import { getTrainingName } from '@/app/components/PublicTrainings/utils';
import { container, divider } from '@/app/jss/common';
import { throwError } from '@/app/redux/error';
import { HOMEPAGE } from '@/app/utils/helper';
import Service from '@/app/utils/service';

import RegistrationSuccessfully from './RegistrationSuccessfully';

type OwnProps = {
  type?: 'external' | 'isolated';
};

type MapDispatchToProps = {
  throwError: typeof throwError;
};

type Props = OwnProps & MapDispatchToProps & RouteComponentProps<{ trainingId?: string }>;

const TrainingRegistration: React.FC<Props> = (props) => {
  const [internalTrainingTypes, setInternalTrainingTypes] = React.useState([]);
  const [externalTrainingTypes, setExternalTrainingTypes] = React.useState([]);
  const [registration, setRegistration] = React.useState();
  const [training, setTraining] = React.useState<any|undefined>(undefined);

  const handleSubmit = (values: any) => {
    return Service.post(
      externalAPIs.publicTrainingRegister(props.match.params.trainingId),
      values,
      (data: any) => { setRegistration(data); },
      (error: any) => {

        props.throwError(error);
      },
    );
  };

  React.useEffect(
    () => {
      Service.get(
        externalAPIs.publicTraining(props.match.params.trainingId),
        (data: any) => { setTraining(data); },
        (error: any) => { props.throwError(error); },
      );

      Service.get(
        internalAPIs.training.listNamesByType('INTERNAL_TRAINING_TYPE', true),
        (data: any) => { setInternalTrainingTypes(data); },
        (error: any) => { props.throwError(error); },
      );

      Service.get(
        internalAPIs.training.listNamesByType('EXTERNAL_TRAINING_TYPE', true),
        (data: any) => { setExternalTrainingTypes(data); },
        (error: any) => { props.throwError(error); },
      );
    },
    [props.match.params.trainingId, props.throwError]
  );

  if (!training) {
    return null;
  }

  if (!training.useOnlineRegistration || training.startDate < moment().format('YYYY-MM-DD')) {
    return <Redirect to={`${HOMEPAGE}/${props.type || 'external'}/trainings`} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} style={container}>
        <TrainingDetails
          title={getTrainingName(training, internalTrainingTypes, externalTrainingTypes)}
          description={training.description}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} style={container}>
        <Hidden mdUp>
          <Divider style={divider} />
        </Hidden>

        {
          registration
            ? <RegistrationSuccessfully registration={registration} />
            : <TrainingRegisterForm onSubmit={handleSubmit} />
        }
      </Grid>
    </Grid>
  );
};

export default connect(null, { throwError })(TrainingRegistration);
