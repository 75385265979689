import * as React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ReducerState } from '@/app/redux/store';
import { throwError } from '@/app/redux/error';
import { Dispatch } from 'redux';
import { changeActivePage, requestAccessCode, APPROVAL_PAGES } from '@/app/redux/documentApproval';
import RequestAccessCode from '@/app/components/DocumentApproval/RequestAccessCode/RequestAccessCode';
import AccessCodeForm from '@/app/components/DocumentApproval/AccessCodeForm/AccessCodeForm';
import { getStringAfterChar, isExpired } from '@/app/utils/helper';
import ApproveDocumentForm from '@/app/components/DocumentApproval/ApproveDocumentForm/ApproveDocumentForm';
import translate from '@/app/utils/translate';

interface MapStateToProps {
  activePage: string;
}

interface MapDispatchToProps {
  throwError: Function;
  changeActivePage: Function;
  requestAccessCode: Function;
}

interface OwnProps {
  location: any;
  match: any;
}

type PropsType = OwnProps & MapDispatchToProps & MapStateToProps;

class DocumentApproval extends React.Component<PropsType, any> {
  componentDidMount() {
    const docAppData = JSON.parse(localStorage.getItem('documentApprovalData'));
    if (docAppData && !isExpired(docAppData.accessTokenExpireTime)) {
      if (docAppData.accessToken) {
        this.props.changeActivePage(APPROVAL_PAGES.APPROVE_DOC);
      } else if (docAppData.entityId && docAppData.rid) {
        this.props.changeActivePage(APPROVAL_PAGES.ACCESS_CODE);
      }
    } else if (docAppData && isExpired(docAppData.accessTokenExpireTime)) {
      localStorage.removeItem('documentApprovalData');
    }
  }

  getEntityId() {
    return parseInt(this.props.match.params.entityId, 10);
  }

  handleRequest = () => {
    const ids = {
      rid: getStringAfterChar(this.props.location.search, 'rid='),
      entityId: this.getEntityId(),
    };

    this.props.requestAccessCode(ids);
  }

  render() {
    const { activePage } = this.props;
    let page = null;

    switch (activePage) {
      case APPROVAL_PAGES.SUCCESS:
        page = (
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                {translate.t('message_success_approval')}
              </CardContent>
            </Card>
          </Grid>
        );
        break;
      case APPROVAL_PAGES.ERROR:
        page = (
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                {translate.t('message_error_approval')}
              </CardContent>
            </Card>
          </Grid>
        );
        break;
      case APPROVAL_PAGES.ACCESS_CODE:
        page = (
          <Grid item xs={12} sm={6} md={4} style={{width: '100%'}}>
            <AccessCodeForm />
          </Grid>
        );
        break;
      case APPROVAL_PAGES.APPROVE_DOC:
        page = (
          <Grid item xs={12} sm={12} md={6} style={{width: '100%'}}>
            <ApproveDocumentForm />
          </Grid>
        );
        break;
      case APPROVAL_PAGES.REQUEST_CODE:
      default:
        page = (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <RequestAccessCode onRequest={this.handleRequest} />
          </Grid>
        );
        break;
    }

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{flexGrow: 1, height: '100%'}}
      >
        {page}
      </Grid>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { documentApproval: docApp } = state;

  return {
    activePage: docApp.get('activePage'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  changeActivePage: (page: string) => dispatch(changeActivePage(page)),
  requestAccessCode: (ids: any) => dispatch<any>(requestAccessCode(ids)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DocumentApproval);