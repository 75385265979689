import * as React from 'react';
import compose from 'recompose/compose';
import { Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import withRoot from '@/app/layouts/withRoot';
import externalStyle from '@/app/layouts/External/externalStyle';
import ExternalRoutes from '@/app/routes/externalRoutes';
import Footer from '@/app/components/Footer/Footer';
import Header from '@/app/components/Header/Header';
import { connect } from 'react-redux';
import { fetchCompanyInfo } from '@/app/redux/companyInfo';
import FetchLanguagesHOC from '@/app/hocs/FetchLanguagesHOC';
import FetchModulesHOC from '@/app/hocs/FetchModulesHOC';
import ErrorHandlerHOC from '@/app/hocs/ErrorHandlerHOC';
import NotifierHOC from '@/app/hocs/NotifierHOC';
import { ReducerState } from '@/app/redux/store';

const isColorValid = (potentialColor: string) => {
  const htmlElementBase = new Option().style;
  htmlElementBase.color = `${potentialColor}`;
  
  return !!htmlElementBase.color;
};

const getCustomizedThemeConfig = (mainColor: string) => ({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: mainColor },
  },
});

class External extends React.Component<any> {
  componentDidMount() {
    this.props.fetchCompanyInfo();
  }
  
  renderLayout() {
    const { classes, companyName } = this.props;

    return companyName && (
      <>
        <Header name={companyName} />
        <div className={classes.wrapper}>
          <Paper className={classes.container}><ExternalRoutes /></Paper>
        </div>
        <Footer />
      </>
    );
  }

  render() {
    const theme = isColorValid(this.props.headerColor) ?
      createMuiTheme(getCustomizedThemeConfig(this.props.headerColor)) :
      undefined;

    return theme ? (
      <MuiThemeProvider theme={theme}>
        {this.renderLayout()}
      </MuiThemeProvider>
    ) : this.renderLayout();
  }
}

const mapStateToProps = (state: ReducerState) => ({
  companyName: state.companyInfo.get('name'),
  headerColor: state.companyInfo.get('headerColor'),
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchCompanyInfo,
    }
  ),
  withStyles(externalStyle),
  withRoot,
  NotifierHOC,
  ErrorHandlerHOC,
  FetchLanguagesHOC,
  FetchModulesHOC,
);

export default enhance(External);
