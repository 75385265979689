import * as React from 'react';
import compose from 'recompose/compose';
import { Button, Typography, WithStyles, Card, CardContent, CardActions, withStyles } from '@material-ui/core';
import translate from '@/app/utils/translate';
import requestAccessCodeStyle from './requestAccessCodeStyle';

interface OwnProps {
  onRequest: Function;
}

type PropsType = OwnProps & WithStyles<typeof requestAccessCodeStyle>;

const RequestAccessCode: React.FunctionComponent<PropsType> = ({ onRequest, classes }) => {
  const handleRequest = (event: React.MouseEvent) => {
    onRequest(event);
  };

  return (
    <Card>
      <CardContent>
        <Typography component="h1" variant="h6" className={classes.title}>
          {translate.t('text_request_access_code')}
        </Typography>
        <Typography component="p" variant="body2" className={classes.text}>
          {translate.t('text_request_code_sms')}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRequest}
        >
          {translate.t('label_request_code')}
        </Button>
      </CardActions>
    </Card>
  );
};

const enhance = compose<PropsType, OwnProps>(
  withStyles(requestAccessCodeStyle),
);

export default enhance(RequestAccessCode);
