import { useEffect } from 'react';

export const useBootstrapJs = () => {
  useEffect(() => {
    const jqueryScript = document.createElement('script');
    const bootstrapScript = document.createElement('script');
    const bootstrapCSS = document.createElement('link');
    const formioCSS = document.createElement('link');

    jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jqueryScript.async = false;

    bootstrapScript.src = 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js';
    bootstrapScript.async = false;

    bootstrapCSS.rel = 'stylesheet';
    bootstrapCSS.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css';

    formioCSS.rel = 'stylesheet';
    formioCSS.href = 'https://formio.github.io/formio.js/dist/formio.full.min.css';

    document.body.appendChild(jqueryScript);
    document.body.appendChild(bootstrapScript);
    document.body.appendChild(bootstrapCSS);
    document.body.appendChild(formioCSS);
  
    return () => {
      document.body.removeChild(jqueryScript);
      document.body.removeChild(bootstrapScript);
      document.body.removeChild(bootstrapCSS);
      document.body.removeChild(formioCSS);
    };
  }, []);
};
