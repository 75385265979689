import * as React from 'react';
import { WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import LanguagesSelect from '@/app/components/LanguagesSelect/LanguagesSelect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import footerStyle from '@/app/components/Footer/footerStyle';
import { ReducerState } from '@/app/redux/store';
import { LanguagesType } from '@/app/redux/languages';

interface MapStateToProps {
  languages: Array<LanguagesType>;
}

type PropsType = MapStateToProps & WithStyles<typeof footerStyle, true>;

const Footer: React.FunctionComponent<PropsType> = (props) => {
  const { classes, languages } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <LanguagesSelect languages={languages} />
        </div>
        <div className={classes.right}>
          <span>
            Powered by {new Date().getFullYear()}{' '}
            <a target="_blank" href="https://www.solaforce.com" className={classes.a}>
              Solaforce
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  languages: state.languages.get('allLanguages'),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(footerStyle)
);

export default enhance(Footer);
