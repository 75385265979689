import { combineReducers } from 'redux';
import { reducer as reduxFormReducer, FormReducer } from 'redux-form';
import { legacy_createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import languages from './languages';
import modules from './modules';
import companyInfo from './companyInfo';
import error from './error';
import enums from './enums';
import entityTranslations from './entityTranslations';
import bonusCalc from './bonusCalc';
import notifier from './notifier';
import confirmDialog from './confirmDialog';
import documentApproval from './documentApproval';
import form from '../middleware/form';
import enumsMiddleware from '@/app/middleware/enums';
import adminSection from './adminSection';
import goals from '@/app/redux/goals';
import goalEvaluations from '@/app/redux/goalEvaluations';
import appraisals from '@/app/redux/appraisals';
import jobEvaluation from '@/app/redux/jobEvaluation';
import appraisalsEvaluations from '@/app/redux/appraisalsEvaluations';
import processTemplates from '@/app/redux/processTemplates';
import processEvaluations from '@/app/redux/processEvaluations';
import surveys from './surveys';
import currentUser from './currentUser';
import orgTree from './orgTree';
import reporting from './reporting';
import quickLinks from './quickLinks';
import jobRequirementEvaluations from './jobRequirementEvaluations';

export type ReducerState = {
  adminSection: any;
  goals: any;
  goalEvaluations: any;
  appraisals: any;
  appraisalsEvaluations: any;
  jobEvaluation: any;
  jobRequirementEvaluations: any,
  currentUser: any;
  bonusCalc: any;
  companyInfo: any; // TODO: find a way to make it an object of Map<CompanyInfoType>
  confirmDialog: any;
  documentApproval: any;
  entityTranslations: any;
  enums: any;
  error: any;
  form: FormReducer;
  languages: any; // TODO: find a way to make it an object with allLanguages = List<LanguagesType>...
  modules: any; // TODO: find a way to make it Array<ModulesType>
  notifier: any;
  surveys: any;
  orgTree: any;
  processTemplates: any;
  processEvaluations: any;
  reporting: any;
  quickLinks: any;
};

const reducers = combineReducers({
  adminSection,
  goals,
  goalEvaluations,
  jobRequirementEvaluations,
  appraisals,
  appraisalsEvaluations,
  jobEvaluation,
  currentUser,
  bonusCalc,
  companyInfo,
  confirmDialog,
  documentApproval,
  enums,
  entityTranslations,
  error,
  form: reduxFormReducer,
  languages,
  modules,
  notifier,
  surveys,
  orgTree,
  processTemplates,
  processEvaluations,
  reporting,
  quickLinks,
});

export function configureStore(): Store {
  const composeEnhancers = composeWithDevTools({});

  return legacy_createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk, form, enumsMiddleware)),
  );
}

const store = configureStore();

export default store;
