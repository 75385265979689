import * as React from 'react';
import { Button, Menu, MenuItem, Fade } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import FetchLanguagesHOC from '@/app/hocs/FetchLanguagesHOC';
import { setLanguage, getCurrentLanguage } from '@/app/utils/helper';
import { LanguagesType } from '@/app/redux/languages';
import { compose, withState } from 'recompose';

type PropsType = {
  languages: LanguagesType[]
};

interface InnerProps extends PropsType {
  anchorEl: HTMLElement;
  setAnchorEl: (target: EventTarget) => void;
}

const LanguagesSelect: React.FunctionComponent<InnerProps> = (props) => {
  const handleClick = (event: React.MouseEvent) => {
    props.setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const handleSelect = (code: string) => {
    props.setAnchorEl(null);
    setLanguage(code);
    window.location.reload();
  };

  const selectedLanguage = (): LanguagesType => {
    const result = props.languages.filter((language: LanguagesType) => language.code === getCurrentLanguage())[0];
    if (!result) {
      return { code: 'en', name: 'English', isDefault: true };
    }
    return result;
  };

  const { anchorEl, languages } = props;

  return (
    <>
      <Button
        aria-owns={anchorEl ? 'fade-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <LanguageIcon />
        {selectedLanguage().name}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {languages.map((language: LanguagesType) => {
          return (
            <MenuItem
              key={language.code}
              value={language.code}
              onClick={() => handleSelect(language.code)}
            >
              {language.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const enhance = compose<InnerProps, PropsType>(
  FetchLanguagesHOC,
  withState('anchorEl', 'setAnchorEl', null)
);

export default enhance(LanguagesSelect);
