import { defaultFont, container, primaryColor } from '@/app/jss/common';
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const footerStyle: StyleRulesCallback = () => ({
  left: {
    float: 'left',
    display: 'block',
  },
  right: {
    padding: '10px 0px',
    margin: '0',
    fontSize: '14px',
    float: 'right',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    padding: '1px 0',
    ...defaultFont,
  },
  container,
  a: {
    color: primaryColor,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
});

export default footerStyle;
