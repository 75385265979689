import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const requestAccessCodeStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    title: {
      paddingBottom: `${theme.spacing.unit * 2}px`,
    },
    buttonContainer: {
      justifyContent: 'flex-end',
    },
  });
};

export default requestAccessCodeStyle;
