import { FormioComponent, FormioComponenTemplateContext } from '../types';

const findNestedComponentRecursive = (
  components: FormioComponent[],
  componentKey: string,
): FormioComponent | undefined => {
  if (!components) {
    return undefined;
  }

  return components.reduce((
    result: FormioComponent,
    component: FormioComponent
  ) => {
    if (component.key === componentKey) {
      return component;
    }

    const foundComponent = findNestedComponentRecursive(
      component.components || component.columns,
      componentKey
    );

    return foundComponent ? foundComponent : result;
  }, undefined);
};

const findNestedComponentInSchemas = (
  schemas: Record<string, FormioComponent>,
  componentKey: string
) => {
  if (schemas[componentKey]) {
    return schemas[componentKey];
  }

  const found = Object.keys(schemas).reduce((
    result: FormioComponent | undefined,
    key: string
  ) => {
    const foundComponent = findNestedComponentRecursive(
      schemas[key].components,
      componentKey
    );

    return foundComponent ? foundComponent : result;
  }, undefined);

  return found;
};

const builder = {
  form: (ctx: any) => `
    <div class="formio builder row formbuilder">
      <div class="col-xs-4 col-sm-3 col-md-3 formcomponents">
        ${ctx.sidebar}
      </div>
      <div class="col-xs-8 col-sm-9 col-md-9 formarea" ref="form">
        ${ctx.form}
      </div>
    </div>
  `,
};

const builderWizard = {
  form: (ctx: any) => `
    <div class="formio builder row formbuilder">
      <div class="col-xs-4 col-sm-3 col-md-3 formcomponents">
        ${ctx.sidebar}
      </div>
      <div class="col-xs-8 col-sm-9 col-md-9 formarea">
        <ol class="breadcrumb">
          ${ctx.pages.reduce(
            (acc: any, page: any, pageIndex: any) => acc + `
              <li>
                <span
                  title="${page.title}"
                  class="mr-2 badge ${
                    pageIndex === ctx.self.page
                      ? 'badge-primary'
                      : 'badge-info'
                    } wizard-page-label"
                  ref="gotoPage"
                >
                  ${page.title}
                </span>
              </li>
            `,
            '',
          )}
          <li>
            <span
              title="${ctx.t('Create Page')}"
              class="mr-2 badge badge-success wizard-page-label"
              ref="addPage"
            >
              <i class="${ctx.iconClass('plus')}"></i> ${ctx.t('Page')}
            </span>
          </li>
        </ol>
        <div ref="form">
          ${ctx.form}
        </div>
      </div>
    </div>
  `,
};

const builderComponent = {
  form: (ctx: FormioComponenTemplateContext) => {
    const showRemoveBtn = ctx.childComponent.builder
      ? !ctx.childComponent.builder.disableRemove
      : true;

    const showEditBtn = ctx.childComponent.builder
      ? !ctx.childComponent.builder.disableEdit
      : true;

    const noDragClass = ctx.childComponent.builder && ctx.childComponent.builder.disableDrag
      ? 'no-drag' : '';

    return `<div class="${noDragClass} builder-component builder-component-${ctx.childComponent.type}" ref="dragComponent">
      ${!ctx.disableBuilderActions ? `
        <div class="component-btn-group" data-noattach="true">
          ${showRemoveBtn ? `<div
            role="button"
            aria-label="Remove button. Click to remove component from the form"
            tabindex="-1"
            class="btn btn-xxs btn-danger component-settings-button component-settings-button-remove"
            ref="removeComponent"
          >
            <i class="${ctx.iconClass('remove')}"></i>
          </div>` : ''
        } ${showEditBtn ? `<div
              role="button"
              aria-label="Edit button. Click to open component settings modal window"
              tabindex="-1"
              class="btn btn-xxs btn-secondary component-settings-button component-settings-button-edit"
              ref="editComponent"
            >
              <i class="${ctx.iconClass('cog')}"></i>
            </div>
          ` : ''
        } </div>` : ''
      }
      ${ctx.html}
    </div>`;
  }, 
};

const builderEditForm = {
  form: (ctx: any) => `
    <div class="row">
      <div class="col col-sm-6">
        <p class="lead">${ctx.t(ctx.componentInfo.title)} ${ctx.t('Component')}</p>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-6">
        <div ref="editForm">
            ${ctx.editForm}
        </div>
      </div>
      <div class="col col-sm-6">
        <div class="card panel preview-panel">
          <div class="card-header">
            <h4 class="card-title mb-0">${ctx.t('Preview')}</h4>
          </div>
          <div class="card-body">
            <div class="component-preview" ref="preview">
              ${ctx.preview}
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <button class="btn btn-success" style="margin-right: 10px;" ref="saveButton">${ctx.t(
            'Save',
          )}</button>
          <button class="btn btn-secondary" style="margin-right: 10px;" ref="cancelButton">${ctx.t(
            'Cancel',
          )}</button>
        </div>
      </div>
    </div>
  `,
};

const fieldset = {
  form: (ctx: any) => `
    <fieldset>
      ${
        ctx.component.legend
          ? `
            <legend ref="header" class="${ctx.component.collapsible ? 'formio-clickable' : ''}">
            ${ctx.t(ctx.component.legend, { _userInput: true })}
            ${
              ctx.component.tooltip
                ? `<i ref="tooltip" tabindex="0" class="${ctx.iconClass(
                    'question-sign',
                  )} text-muted" data-tooltip="${ctx.component.tooltip}"></i>`
                : ''
            }
            </legend><hr/>`
          : ''
      }
      ${
        !ctx.collapsed
          ? `
          <div class="fieldset-body" ref="${ctx.nestedKey}">
            ${ctx.children}
          </div>`
          : ''
      }
    </fieldset>`,
};

const builderComponents = {
  form: (ctx: any) => {
    const found = findNestedComponentInSchemas(ctx.instance.schemas, ctx.key);
    const noDrag = found && found.builder && found.builder.disableDrag ? 'no-drag' : '';
    const noDrop = found && found.builder && found.builder.disableDrop ? 'no-drop' : '';

    var _t, _p = '';
    _p += `<div class="${noDrag} ${noDrop} builder-components drag-container formio-builder-` +
    ((_t = (ctx.type)) == null ? '' : _t) +
    '" ref="' +
    ((_t = (ctx.key)) == null ? '' : _t) +
    '-container">\n  ' +
    ((_t = (ctx.html)) == null ? '' : _t) +
    '\n</div>\n';

    return _p;
  }
};

export {
  builder,
  builderWizard,
  builderEditForm,
  builderComponent,
  builderComponents,
  fieldset,
};
