export const useStyles = () => ({
  formPageContainer: {
    marginBottom: '30px',
    marginLeft: '50px',
  },
  componentsContainer: {
    margin: '10px 0 40px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageParagraphs: {
    marginLeft: '20px',
    marginTop: '20px',
  },
  backToStandard: {
    marginLeft: '20px',
    cursor: 'pointer',
    fontWeight: 'normal',
  },
});
