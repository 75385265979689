interface FormioColumn {
  components: Record<string, string | number | boolean | object>[];
  width: string;
  offset: number;
  push: number;
  pull: number;
  size: string;
}

export interface FormioCustomNavButtons {
  input: boolean;
  tableView: boolean;
  key: string;
  type: string;
  hideLabel: boolean;
  columns: FormioColumn[];
  builder: {
    onlyOnce: boolean;
    initialEdit: boolean;
    disableEdit: boolean;
    disableRemove: boolean;
    disableDrag: boolean;
    disableDrop: boolean;
    isMandatory: boolean;
  };
}

const customButton = (event: string, key: string, label: string, theme = 'primary') => {
  return {
    components: [{
      input: true,
      label,
      tableView: false,
      key,
      block: true,
      action: 'event',
      theme,
      type: 'button',
      event,
      hideOnChildrenHidden: false,
      builder: {
        onlyOnce: true,
        initialEdit: false,
        disableEdit: true,
        disableRemove: true,
        disableDrag: true,
        disableDrop: false,
        isMandatory: true,
      },
    }],
    width: 'auto',
    offset: 0,
    push: 0,
    pull: 0,
    size: 'md',
  };
};

export const formioNavButtons = (identifier: string, cancel = true, previous: boolean, next: boolean, submit = false) => {
  const columns = [];

  if (cancel) {
    columns.push(customButton('cancel', `navButtonCancel${identifier}`, 'fCancel', 'secondary'));
  }
  if (previous) {
    columns.push(customButton('goToPreviousPage', `navButtonPrev${identifier}`, 'fPrevious'));
  }
  if (next) {
    columns.push(customButton('goToNextPage', `navButtonNext${identifier}`, 'fNext'));
  }
  if (submit) {
    columns.push(customButton('customSubmit', `navButtonSubmit${identifier}`, 'fSubmit'));
  }

  return {
    input: false,
    tableView: false,
    key: `navColumnsPage${identifier}`,
    type: 'columns',
    hideLabel: true,
    columns,
    builder: {
      onlyOnce: true,
      initialEdit: false,
      disableEdit: true,
      disableRemove: true,
      disableDrag: true,
      disableDrop: false,
      isMandatory: true,
    },
  };
};
