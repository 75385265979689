import * as React from 'react';
import compose from 'recompose/compose';
import { Grid, withStyles } from '@material-ui/core';
import withRoot from '@/app/layouts/withRoot';
import IsolatedRoutes from '@/app/routes/isolatedRoutes';
import { connect } from 'react-redux';
import FetchLanguagesHOC from '@/app/hocs/FetchLanguagesHOC';
import FetchModulesHOC from '@/app/hocs/FetchModulesHOC';
import ErrorHandlerHOC from '@/app/hocs/ErrorHandlerHOC';
import NotifierHOC from '@/app/hocs/NotifierHOC';
import isolatedStyle from '@/app/layouts/Isolated/isolatedStyle';
import { ReducerState } from '@/app/redux/store';

const Isolated = () => (
  <Grid><IsolatedRoutes /></Grid>
);

const mapStateToProps = (state: ReducerState) => ({
  companyName: state.companyInfo.get('name'),
});

const enhance = compose(
  connect(mapStateToProps),
  withRoot,
  withStyles(isolatedStyle),
  NotifierHOC,
  ErrorHandlerHOC,
  FetchLanguagesHOC,
  FetchModulesHOC,
);

export default enhance(Isolated);
