import { Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import * as React from 'react';

import { icon } from '@/app/jss/common';
import translate from '@/app/utils/translate';

type Props = {
  registration: any;
};

const RegistrationSuccessfully: React.FC<Props> = ({ registration }) => {
  return (
    <>
      <Typography
        style={{color: 'green', display: 'flex', alignItems: 'center'}}
        variant="h6"
        gutterBottom
      >
        <CheckIcon style={icon} /> {translate.t('laRegisteredSuccessfullyForTraining')}
      </Typography>
      <Typography variant="body1" component="div">
        <Grid container>
          <Grid item xs={4}>{translate.t('laFirstName')}:</Grid>
          <Grid item xs={8}>{registration.participant.firstName}</Grid>

          <Grid item xs={4}>{translate.t('laGivenNames')}:</Grid>
          <Grid item xs={8}>{registration.participant.givenNames}</Grid>

          <Grid item xs={4}>{translate.t('laLastName')}:</Grid>
          <Grid item xs={8}>{registration.participant.lastName}</Grid>

          <Grid item xs={4}>{translate.t('laEmail')}:</Grid>
          <Grid item xs={8}>{registration.participant.email}</Grid>

          <Grid item xs={4}>{translate.t('laPhone')}:</Grid>
          <Grid item xs={8}>{registration.participant.phone}</Grid>

          {!registration.participant.message ? '' : (
            <>
              <Grid item xs={4}>{translate.t('laMessageToTrainer')}:</Grid>
              <Grid item xs={8}>{registration.participant.message}</Grid>
            </>
          )}
        </Grid>
      </Typography>
    </>
  );
};

export default RegistrationSuccessfully;
