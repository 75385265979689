import { StyleRulesCallback } from '@material-ui/core';

const trainingDetailsStyle: StyleRulesCallback = theme => {
  return {
    root: {
      width: '100%',
    },
    mainDetailsContainer: {
      display: 'flex',
    },
    mainDetails: {
      flexGrow: 1,
      flexBasis: 60,
      flexDirection: 'row',
      flexFlow: 'row wrap',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    registerButton: {
      color: theme.palette.common.white,
    },
  };
};

export default trainingDetailsStyle;
