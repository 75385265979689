import {
  Button,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { sanitize } from 'dompurify';
import * as React from 'react';

import { getTrainingGroupName } from '@/app/components/Trainings/helpers';
import translate from '@/app/utils/translate';

import trainingDetailsStyle from './trainingDetailsStyle';
import moment from 'moment';

type OwnProps = {
  training: any;
  onRegister: (trainingId: string) => void;
};

type Props = OwnProps & WithStyles<typeof trainingDetailsStyle>;

const TrainingDetails: React.FC<Props> = ({
  classes,
  training,
  onRegister,
}) => {
  const handleRegister = () => {
    onRegister(training.id);
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainDetailsContainer}>
        <Grid container>
          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {translate.t('laType')}:&nbsp;
            </Typography>
            <Typography inline>
              {getTrainingGroupName(training.trainingGroup)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {translate.t('laFee')}:&nbsp;
            </Typography>
            <Typography inline>
              {training.fee} {training.currency}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {translate.t('laProvider')}:&nbsp;
            </Typography>
            <Typography inline>{training.provider}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {translate.t('laHours')}:&nbsp;
            </Typography>
            <Typography inline>{training.hours}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {translate.t('laWorkContractLocation')}:&nbsp;
            </Typography>
            <Typography inline>{training.city}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {`${translate.t('fEmpOfficeRoom')}/${translate.t('laVenue')}`}
              :&nbsp;
            </Typography>
            <Typography inline>{training.room}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {`${translate.t('fEmpOfficeRoom')}/${translate.t('laVenue')}`}
              :&nbsp;
            </Typography>
            <Typography inline>{training.room}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography inline color="textSecondary">
              {`${translate.t('fEmpOfficeRoom')}/${translate.t('laVenue')}`}
              :&nbsp;
            </Typography>
            <Typography inline>{training.room}</Typography>
          </Grid>
          <Grid item container xs={12}>
            {training.trainingTime.startTime && (
              <Grid item xs={4}>
                <Typography inline color="textSecondary">
                  {`${translate.t('laStartTime')}`}
                  :&nbsp;
                </Typography>
                <Typography inline>
                  {training.trainingTime.startTime}
                </Typography>
              </Grid>
            )}
            {training.trainingTime.endTime && (
              <Grid item xs={4}>
                <Typography inline color="textSecondary">
                  {`${translate.t('laEndTime')}`}
                  :&nbsp;
                </Typography>
                <Typography inline>{training.trainingTime.endTime}</Typography>
              </Grid>
            )}
          </Grid>
          {training.teacherName && (
            <Grid item xs={12}>
              <Typography inline color="textSecondary">
                {translate.t('laTeacherName')}:&nbsp;
              </Typography>
              <Typography inline>{training.teacherName}</Typography>
            </Grid>
          )}
          {training.contactPerson && (
            <Grid item xs={12}>
              <Typography inline color="textSecondary">
                {translate.t('laContactPerson')}:&nbsp;
              </Typography>
              <Typography inline>{training.contactPerson}</Typography>
            </Grid>
          )}

          {training.useOnlineRegistration && (
            <>
              <Grid item xs={12}>
                {training.lastDateForRegistration &&
                !moment(new Date()).isSameOrBefore(
                  training.lastDateForRegistration,
                  'day',
                ) ? null : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRegister}
                    className={classes.registerButton}
                  >
                    {translate.t('label_register_for_training')}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                {training.lastDateOfCancellation && (
                  <Grid item xs={12}>
                    <Typography inline color="textSecondary">
                      {translate.t('laLastCancellationDate')}:&nbsp;
                    </Typography>
                    <Typography inline>
                      {moment(training.lastDateOfCancellation).format(
                        'DD.MM.YYYY',
                      )}
                    </Typography>
                  </Grid>
                )}
                {training.lastDateForRegistration && (
                  <Grid item xs={12}>
                    <Typography inline color="textSecondary">
                      {translate.t('lastDateForRegistration')}:&nbsp;
                    </Typography>
                    <Typography inline>
                      {moment(training.lastDateForRegistration).format(
                        'DD.MM.YYYY',
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: sanitize(training.description) }}
      />
    </div>
  );
};

export default withStyles(trainingDetailsStyle)(TrainingDetails);
