import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const externalStyle: StyleRulesCallback = (theme: Theme) => ({
  '@global': {
    'body': {
      backgroundColor: theme.palette.background.paper,
    }
  }
});

export default externalStyle;
