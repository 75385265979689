import { StyleRulesCallback } from '@material-ui/core';

const trainingsListStyle: StyleRulesCallback = (theme) => {
  return ({
    list: {
      marginTop: theme.spacing.unit * 2,
    },
  });
};

export default trainingsListStyle;