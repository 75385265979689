import { Typography, Button, FormControl } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, InjectedFormProps } from 'redux-form';

import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import { throwError } from '@/app/redux/error';
import { INPUT_TYPE, isValidEmail } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

type OwnProps = {
  onSubmit: (values: any) => void;
};

type Props = OwnProps & InjectedFormProps;

const TrainingRegisterForm: React.FC<Props> = ({
  valid,
  pristine,
  submitting,
  handleSubmit,
}) => {
  const inputs: InputType[] = React.useMemo(() => {
    return [
      {
        type: INPUT_TYPE.TEXT,
        code: 'firstName',
        name: 'firstName',
        label: translate.t('laFirstName'),
        order: 1,
        required: true,
      },
      {
        type: INPUT_TYPE.TEXT,
        code: 'givenNames',
        name: 'givenNames',
        label: translate.t('laGivenNames'),
        order: 2,
        required: true,
      },
      {
        type: INPUT_TYPE.TEXT,
        code: 'lastName',
        name: 'lastName',
        label: translate.t('laLastName'),
        order: 3,
        required: true,
      },
      {
        type: INPUT_TYPE.EMAIL,
        code: 'email',
        name: 'email',
        label: translate.t('laEmail'),
        order: 4,
        required: true,
      },
      {
        type: INPUT_TYPE.TEL,
        code: 'phone',
        name: 'phone',
        label: translate.t('laPhone'),
        order: 5,
        required: true,
      },
      {
        type: INPUT_TYPE.TEXTAREA,
        code: 'message',
        name: 'message',
        label: translate.t('laMessageToTrainer'),
        order: 6,
        required: false,
        limit: 3000,
      },
    ];
  }, []);

  const disabled = !valid || pristine || submitting;

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        {translate.t('label_register_for_training')}
      </Typography>

      <FormFields inputs={inputs} />

      <FormControl margin="normal">
        <Button
          fullWidth={false}
          size="small"
          variant="contained"
          color="primary"
          disabled={disabled}
          type="submit"
        >
          {translate.t('laSubmit')}
        </Button>
      </FormControl>
    </form>
  );
};

const validate = (values: any, _props: any) => {
  let errors: { [k: string]: any } = {};

  // check all required fields
  ['firstName', 'givenNames', 'lastName', 'email', 'phone'].forEach((field) => {
    if (!values[field]) {
      errors[field] = translate.t('laThisRequired');
    }
  });

  if (!isValidEmail(values.email)) {
    errors.email = translate.t('laValidEmail');
  }

  return errors;
};

const enhance = compose<Props, OwnProps>(
  connect(null, { throwError }),
  reduxForm({ form: 'TrainingRegisterForm', validate }),
);

export default enhance(TrainingRegisterForm);
