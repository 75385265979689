import { Builders, Components, Formio, Templates } from 'formiojs';

import { requestProxy } from './plugins';
import { CustomWebformBuilder } from './setup/CustomWebformBuilder';
import { CustomWizardBuilder } from './setup/CustomWizardbuilder';
import {
  builder,
  builderWizard,
  builderEditForm,
  builderComponent,
  builderComponents,
  fieldset,
} from './templates';

export const applyCustomFormioConfig = () => {
  Formio.registerPlugin(...requestProxy);

  Templates.current = {
    builder,
    builderWizard,
    builderEditForm,
    builderComponent,
    builderComponents,
    fieldset,
  };

  Builders.addBuilder('webform', CustomWebformBuilder);
  Builders.addBuilder('wizard', CustomWizardBuilder);
  
  class EnumValue extends Components.components.select {}
  class DateComponent extends Components.components.datetime {}
  class ComponentGroup extends Components.components.fieldset {}
  class ColumnsComponentGroup extends Components.components.columns {}

  class TextfieldMandatory extends Components.components.textfield {}
  class DateMandatory extends Components.components.datetime {}
  class EnumValueMandatory extends Components.components.select {}
  class SelectMandatory extends Components.components.select {}
  
  EnumValue.editForm = Components.components.select.editForm;
  DateComponent.editForm = Components.components.datetime.editForm;
  ComponentGroup.editForm = Components.components.fieldset.editForm;
  ColumnsComponentGroup.editForm = Components.components.columns.editForm;

  TextfieldMandatory.editForm = Components.components.textfield.editForm;
  DateMandatory.editForm = Components.components.datetime.editForm;
  EnumValueMandatory.editForm = Components.components.select.editForm;
  SelectMandatory.editForm = Components.components.select.editForm;
  
  Components.setComponent('textfieldMandatory', TextfieldMandatory);
  Components.setComponent('dateMandatory', DateMandatory);
  Components.setComponent('enumValueMandatory', EnumValueMandatory);
  Components.setComponent('selectMandatory', SelectMandatory);

  Components.setComponent('enumValue', EnumValue);
  Components.setComponent('date', DateComponent);
  Components.setComponent('componentGroup', ComponentGroup);
  Components.setComponent('columnsComponentGroup', ColumnsComponentGroup);
};
