import { Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@/app/utils/helper';

import { getTrainingName } from '../utils';

type Props = {
  training: any;
  internalTrainingTypes: any[];
  externalTrainingTypes: any[];
};

const TrainingSummary: React.FC<Props> = ({ training, internalTrainingTypes, externalTrainingTypes }) => {
  const formattedDate = React.useMemo(
    () => {
      const formattedStartDate = moment(training.startDate, SERVER_DATE_FORMAT).format(DATE_FORMAT);
      const formattedEndDate = training.endDate && moment(training.endDate, SERVER_DATE_FORMAT).format(DATE_FORMAT);

      return `${formattedStartDate}${formattedEndDate ? ` - ${formattedEndDate}` : ''}`;
    },
    [training.startDate, training.endDate]
  );

  const trainingName = React.useMemo(
    () => getTrainingName(training, internalTrainingTypes, externalTrainingTypes),
    [training, internalTrainingTypes, externalTrainingTypes]
  );

  return <Typography>{`${trainingName} (${formattedDate})`}</Typography>;
};

export default TrainingSummary;
