const TermsTranslationMock = {
  'Simple survey': [{
    language: 'en',
    terms: {
      'Simple survey': {
        'Simple survey': 'Some simple survey'
      },
      'Question': {
        question: 'Is this a good idea in your mind?',
        trueChoice: 'Yes',
        falseChoice: 'No',
      },
    }
  }, {
    language: 'es',
    terms: {
      'Simple survey': {
        'Simple survey': 'Alguna encuesta simple'
      },
      'Question': {
        question: '¿Es esta una buena idea en tu mente?',
        trueChoice: 'Sí',
        falseChoice: 'No',
      },
    }
  }],
  'Company Survey 2018': [{
    language: 'en',
    terms: {
      'Survey name': {
        'Survey name': 'Some new survey'
      },
      'Question 1': {
        question: 'Is this a good idea in your mind?',
        trueChoice: 'Yes',
        falseChoice: 'No',
      },
      'Question 2': {
        question: 'What is important in your work?',
        options: [
          'Enough challange',
          'Good pay',
        ]
      }
    }
  }, {
    language: 'fi',
    terms: {
      'Survey name': {
        'Survey name': 'Joku uusi kysely'
      },
      'Question 1': {
        question: 'Onko tämä mielestäsi hyvä idea?',
        trueChoice: 'Kyllä',
        falseChoice: 'Ei',
      },
      'Question 2': {
        question: 'Mikä on tärkeää työssäsi?',
        options: [
          'Riittävästi haasteita',
          'Hyvä palkka',
        ]
      }
    }
  }]
};

export default TermsTranslationMock;
