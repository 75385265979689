import * as React from 'react';
import { connect, Provider } from 'react-redux';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import store from '@/old/store';
import { getCurrentLanguage } from '@/app/utils/helper';
import { ReducerState } from '@/app/redux/store';
import { LanguagesType } from '@/app/redux/languages';
import { container } from '@/app/jss/common';
import TranslateTermsDialog from '@/app/components/TranslateTermsDialog/TranslateTermsDialog';
import TermsTranslationMock from '@/app/pages/Test/TermsTranslationMock';

const TERM_DATA_STORAGE_KEY = 'TERM_DATA_STORAGE_KEY';

type StateType = {
  open: boolean;
  languages: LanguagesType[],
  references: string[];
  referenceData: any;
  translationData: any;
  selectedRef: string;
  selectedTermSet: string;
  selectedLanguage: string;
};

class Test extends React.Component<any> {
  termData: Object;
  state: StateType = {
    open: false,
    languages: [],
    references: [],
    referenceData: { terms: {} },
    translationData: { terms: {} },
    selectedRef: getCurrentLanguage(),
    selectedTermSet: null,
    selectedLanguage: getCurrentLanguage(),
  };

  componentDidMount() {
    const termData = localStorage.getItem(TERM_DATA_STORAGE_KEY);
    if (termData) {
      this.termData = JSON.parse(termData);
    } else {
      localStorage.setItem(TERM_DATA_STORAGE_KEY, JSON.stringify(TermsTranslationMock));
      this.termData = Object.assign({}, TermsTranslationMock);
    }

    this.setState({ references: Object.keys(this.termData) });
  }

  getEmptyGroup(group: object) {
    const obj = {};
    for (const key in group) {
      if (typeof group[key] !== 'string') {
        obj[key] = this.getEmptyGroup(group[key]);
      } else {
        obj[key] = '';
      }
    }

    return obj;
  }

  getTranslationData(refKey: string, trKey: string) {
    let trData = this.termData[refKey].find((ref: any) => (ref.language === trKey));

    if (trData) {
      return trData;
    }

    return Object.assign({}, this.getEmptyGroup(this.termData[refKey][0]), {
      language: trKey,
    });
  }

  getUpdatedTerms(saveData: any) {
    const reduceCallback = (accumulator: Object, termKey: string) => {
      if (termKey !== this.state.selectedTermSet) {
        return Object.assign({}, accumulator, { [termKey]: this.termData[termKey] });
      }

      const langSetFound = this.termData[termKey].find((langSet: any) => langSet.language === saveData.language);
      if (!langSetFound) {
        return Object.assign({}, accumulator, {
          [termKey]: [saveData].concat(this.termData[termKey]),
        });
      }

      return Object.assign({}, accumulator, {
        [termKey]: this.termData[termKey].map((langSet: any) => {
          if (langSet.language === saveData.language) {
            return saveData;
          }

          return langSet;
        }),
      });
    };

    return Object.keys(this.termData).reduce(reduceCallback, {});
  }

  toggleTranslateTermsDialog = () => {
    this.setState({ open: !this.state.open });
  }

  handleTermSet = (termSetKey: string) => {
    const language = getCurrentLanguage();
    this.setState({
      referenceData: this.getTranslationData(termSetKey, language),
      translationData: this.getTranslationData(termSetKey, language),
      selectedTermSet: termSetKey,
      selectedRef: language,
      selectedLanguage: language,
    });
  }

  handleSave = (saveData: any) => {
    this.toggleTranslateTermsDialog();
    this.termData = this.getUpdatedTerms(saveData);
    localStorage.setItem(TERM_DATA_STORAGE_KEY, JSON.stringify(this.termData));
  }

  handleTrChange = (
    refKey: string,
    trKey: string,
    stateFragmentData: string,
    stateFragmentLang: string
  ) => {
    this.setState({
      [stateFragmentData]: this.getTranslationData(refKey, trKey),
      [stateFragmentLang]: trKey,
    });
  }

  render() {
    const {
      open,
      references,
      referenceData,
      translationData,
      selectedTermSet,
      selectedLanguage,
      selectedRef
    } = this.state;
    const { languages } = this.props;
    const termSets = references.map(termSetKey => (
      <Button
        key={termSetKey}
        onClick={() => {
          this.toggleTranslateTermsDialog();
          this.handleTermSet(termSetKey);
        }}
      >
        {termSetKey}
      </Button>
    ));

    return (
      <div style={container}>
        {termSets}
        <Provider store={store}>
          <TranslateTermsDialog
            open={open}
            title={`Translate survey ${selectedTermSet ? ` - ${selectedTermSet}` : ''}`}
            references={languages}
            languages={languages}
            referenceData={referenceData}
            translationData={translationData}
            selectedRefLang={selectedRef}
            selectedTransLang={selectedLanguage}
            onRefChange={change => this.handleTrChange(selectedTermSet, change, 'referenceData', 'selectedRef')}
            onTrChange={change => this.handleTrChange(selectedTermSet, change, 'translationData', 'selectedLanguage')}
            onClose={this.toggleTranslateTermsDialog}
            onSave={this.handleSave}
          />
        </Provider>
      </div>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  languages: state.languages.get('allLanguages'),
});

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(Test);