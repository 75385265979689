import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  InputAdornment,
  OutlinedInput,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import externalAPIs from '@/app/api/externalAPIs';
import internalAPIs from '@/app/api/internalAPIs';
import { throwError } from '@/app/redux/error';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';

import TrainingDetails from './TrainingDetails';
import TrainingSummary from './TrainingSummary';
import trainingsListStyle from './trainingsListStyle';
import { mapCompanyTrainingForPublicUI } from '../../Trainings/context/trainingsContext/dataMappers';

type OwnProps = {
  onRegisterForTraining: (trainingId: string) => void;
};

type MapDispatchToProps = {
  throwError: typeof throwError;
};

type Props = OwnProps & MapDispatchToProps & WithStyles<typeof trainingsListStyle>;

const TrainingsList: React.FC<Props> = (props) => {
  const [internalTrainingTypes, setInternalTrainingTypes] = React.useState([]);
  const [externalTrainingTypes, setExternalTrainingTypes] = React.useState([]);
  const [expandedTrainings, setExpandedTrainings] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [trainings, setTrainings] = React.useState<any[]>();

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleTraining = (trainingId: string) => {
    return () => {
      setExpandedTrainings(
        expandedTrainings.includes(trainingId)
          ? expandedTrainings.filter((et) => et !== trainingId)
          : [...expandedTrainings, trainingId]
      );
    };
  };

  React.useEffect(
    () => {
      Service.get(
        externalAPIs.publicTrainings,
        (data: any[]) => {
          setTrainings(
            data
              .filter(({ training }) => {
                return training.startDate >= moment().format('YYYY-MM-DD');
              })
              .sort((a, b) => {
                if (a.training.startDate < b.training.startDate) {
                  return -1;
                }

                if (a.training.startDate > b.training.startDate) {
                  return 1;
                }

                return 0;
              })
              .map(({ training, summary }) => ({
                summary,
                training: mapCompanyTrainingForPublicUI(training),
              })),
          );
        },
        (error: any) => { props.throwError(error); },
      );

      Service.get(
        internalAPIs.training.listNamesByType('INTERNAL_TRAINING_TYPE', true),
        (data: any) => { setInternalTrainingTypes(data); },
        (error: any) => { props.throwError(error); },
      );

      Service.get(
        internalAPIs.training.listNamesByType('EXTERNAL_TRAINING_TYPE', true),
        (data: any) => { setExternalTrainingTypes(data); },
        (error: any) => { props.throwError(error); },
      );
    },
    [props.throwError]
  );

  const filteredTrainings = React.useMemo(
    () => {
      if (!trainings) {
        return [];
      }

      if (!searchQuery) {
        return trainings;
      }

      const query = searchQuery.toLocaleLowerCase();

      return trainings.filter(({ training }) => {
        return (
          training.trainingGroup.toLowerCase().includes(query) ||
          training.trainingType.toLowerCase().includes(query) ||
          training.provider.toLowerCase().includes(query) ||
          training.description.toLowerCase().includes(query)
        );
      });
    },
    [searchQuery, trainings]
  );

  if (!trainings) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {translate.t('training_calendar')}
      </Typography>

      <OutlinedInput
        value={searchQuery}
        onChange={handleSearchQueryChange}
        margin="dense"
        labelWidth={0}
        fullWidth={true}
        placeholder={translate.t('laSearch')}
        startAdornment={(
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )}
      />

      <div className={props.classes.list}>
        {filteredTrainings.map(({ training }) => {
          return (
            <ExpansionPanel
              key={training.id}
              expanded={expandedTrainings.includes(training.id)}
              onChange={handleToggleTraining(training.id)}
            >
              <ExpansionPanelSummary>
                <TrainingSummary
                  training={training}
                  internalTrainingTypes={internalTrainingTypes}
                  externalTrainingTypes={externalTrainingTypes}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TrainingDetails
                  training={training}
                  onRegister={props.onRegisterForTraining}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}

        {!trainings.length && (
          <Typography>
            {translate.t('laNoPublicTrainingsAvailable')}
          </Typography>
        )}

        {!!trainings.length && !filteredTrainings.length && (
          <Typography>{translate.t('laNoMatchingPublicTrainings')}</Typography>
        )}
      </div>
    </div>
  );
};

export default compose<Props, OwnProps>(
  connect(null, { throwError }),
  withStyles(trainingsListStyle)
)(TrainingsList);
