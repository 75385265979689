import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import API from '@/app/api/internalAPIs';
import { HOMEPAGE } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { FormBuilder } from 'formiojs';
import { getLoggedUserId } from '@/old/utils/helper';
import { compose } from 'recompose';

import {
  addCustomNavButtonSettings,
  fetchAllFormTemplates,
  fetchLatestTemplateVersion,
  getAllComponentsFromTemplate,
  getFormioFriendlyCurrentLanguage,
  normalizeString,
  overrideDefaultTranslations,
  removeCustomDataPage,
  removeNavButtonSettings,
} from '../common';
import { applyCustomFormioConfig } from '../components/CustomBuilder';
import { updateMenu } from '../components/CustomBuilder/updateMenu';
import {
  FormioExtendedComponentSchema,
  FormioFormBuilderConfig,
  FormioFormBuilderOptions,
} from '../components/CustomBuilder/types';
import { cssStyling } from '../components/CustomBuilder/css';

import { TemplateListItem } from '../types';
import { useBootstrapJs } from '../useBootstrapJs';

import { styles } from './styles';
import { useFormsCustomConfig } from '@/app/hooks/useFormsCustomConfig';
import TemplateUpdateIconBtns from '../components/TemplateUpdateIconBtns';

type RouteParams = { formId: string };
type Props = RouteComponentProps<RouteParams> & WithStyles<typeof styles>;

applyCustomFormioConfig();

const extractFormId = (params?: RouteParams) => {
  if (!params || !params.formId || params.formId === 'new') {
    return '';
  }

  return params.formId;
};

const isComponentMandatory = (componentSchema: FormioExtendedComponentSchema) =>
  componentSchema &&
  componentSchema.builder &&
  componentSchema.builder.isMandatory;

const isFormTemplateNameValid = (
  currentFormId: string,
  currentTitle: string,
  existingTemplates: TemplateListItem[],
) => {
  return !existingTemplates
    .filter(tpl => tpl._id !== currentFormId)
    .find(tpl => tpl.title.toLowerCase() === currentTitle.toLowerCase());
};

const EditFormTemplateDialog = (props: Props) => {
  const { classes, history, match } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [tplNameValidity, setTplNameValidity] = useState(true);
  const [formTemplates, setFormTemplates] = useState<TemplateListItem[]>([]);
  const [tplValidityStatus, setTplValidityStatus] = useState(true);
  const [formConfig, setFormConfig] = useState<FormioFormBuilderConfig>();
  const [formOptions, setFormOptions] = useState<FormioFormBuilderOptions>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [builderInstance, setBuilderInstance] = useState<FormBuilder>();
  const [builderRootEl, setBuilderRootEl] = useState<HTMLElement>();
  const [latestVersion, setLatestVersion] = useState<string>();
  const rootElCallbackRef = useCallback(node => {
    if (node) {
      setBuilderRootEl(node);
    }
  }, []);

  const currentFormId = useMemo(() => extractFormId(match.params), [match.params]);
  const { applyCustomConfig, customDataComponents } = useFormsCustomConfig(currentFormId, true);

  useBootstrapJs();
  useEffect(() => {
    fetchAllFormTemplates().then((resp: TemplateListItem[]) => {
      setFormTemplates(resp);
    });
  }, [setFormTemplates]);
  
  useEffect(() => {
    fetchLatestTemplateVersion().then((version) => {
      setLatestVersion(version);
    });
  }, []);
  
  useEffect(() => {
    setFormConfig(undefined);
    setIsLoading(true);

    const formConfigEndpoint = currentFormId
      ? API.forms.formGeneral(currentFormId)
      : API.forms.formDefault('employment');

    const formioLang = getFormioFriendlyCurrentLanguage();
    Service.get(
      API.forms.getBuilder('employment', formioLang),
      (resp: FormioFormBuilderOptions) => {
        setFormOptions({
          ...resp,
          language: formioLang,
          i18n: overrideDefaultTranslations(resp.i18n, formioLang),
        });
        Service.get(
          formConfigEndpoint,
          (formConfigResp: FormioFormBuilderConfig) => {
            removeNavButtonSettings(formConfigResp, currentFormId);
            if (currentFormId) {
              if (customDataComponents.length) {
                applyCustomConfig(formConfigResp);
                setFormConfig(formConfigResp);
              }
            } else {
              setFormConfig(formConfigResp);
            }
            setIsLoading(false);
          },
          (_err: Error) => {
            setFormConfig({ display: 'wizard', title: '' });
          },
        );
      },
      (_err: Error) => {
        setIsLoading(false);
      },
    );
  }, [
    currentFormId,
    customDataComponents,
    setFormConfig,
    setFormOptions,
    setIsLoading,
  ]);

  const cancelHandler = useCallback(() => {
    history.push(`${HOMEPAGE}/isolated/form/builder/employment`);
  }, [history]);

  const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTplNameValidity(true);
    setFormConfig({
      ...formConfig,
      title: event.target.value,
    });
  }, [formConfig, setFormConfig, setTplNameValidity]);

  const editForm = useCallback((reqBody: any) => {
    Service.put(
      API.forms.formGeneral(currentFormId),
      reqBody,
      () => {
        cancelHandler();
      },
      (err: Error) => {
        console.log(err);
      },
    );
  }, [cancelHandler, currentFormId]);

  const createForm = useCallback((reqBody: any) => {
    Service.post(
      API.forms.createForm(),
      reqBody,
      () => {
        cancelHandler();
      },
      (err: Error) => {
        console.log(err);
      },
    );
  }, [cancelHandler]);

  const isTemplateValid = useCallback((): boolean => {
    if (
      !formConfig.title
      || !isFormTemplateNameValid(currentFormId, formConfig.title, formTemplates)
    ) {
      setTplNameValidity(false);
      return false;
    }

    const allBuilderComponents = Object.keys(formOptions.builder).reduce(
      (acc: object, bKey) => {
        if (
          !formOptions.builder[bKey] ||
          !formOptions.builder[bKey].components
        ) {
          return acc;
        }

        return {
          ...acc,
          ...formOptions.builder[bKey].components,
        };
      },
      {},
    );

    const componentKeys = getAllComponentsFromTemplate(
      formConfig.components,
    ).map(comp => comp.key);
    const mandatoryFormFieldKeys = Object.keys(allBuilderComponents)
      .filter((compKey: string) => {
        const isFieldMandatory = isComponentMandatory(
          allBuilderComponents[compKey].schema,
        );
        const includesMandatoryComponentFields =
          allBuilderComponents[compKey].schema &&
          allBuilderComponents[compKey].schema.components &&
          allBuilderComponents[compKey].schema.components.find(
            (comp: FormioExtendedComponentSchema) => {
              return isComponentMandatory(comp);
            },
          );

        const hasMandatoryFieldsColumnFields =
          allBuilderComponents[compKey].schema &&
          allBuilderComponents[compKey].schema.columns &&
          allBuilderComponents[compKey].schema.columns.find((col: any) =>
            col.components.find((comp: FormioExtendedComponentSchema) => {
              return isComponentMandatory(comp);
            }),
          );

        return (
          isFieldMandatory ||
          includesMandatoryComponentFields ||
          hasMandatoryFieldsColumnFields
        );
      })
      .map((compKey: string) => allBuilderComponents[compKey].schema.key);

    const foundMissing = mandatoryFormFieldKeys.find(
      key => !componentKeys.includes(key),
    );
    setTplValidityStatus(!foundMissing);

    return !foundMissing;
  }, [
    currentFormId,
    formConfig,
    formOptions,
    formTemplates,
    setTplValidityStatus,
    setTplNameValidity,
  ]);

  const handleSubmit = useCallback(() => {
    setIsSubmitted(true);

    if (!isTemplateValid()) {
      return;
    }

    const userId = getLoggedUserId();
    const name =
      formConfig && formConfig.name
        ? formConfig.name
        : normalizeString(formConfig.title.replace(/\s+/g, '-').toLowerCase());
    const path =
      formConfig && formConfig.path ? formConfig.path : `employment/${name}`;
    const reqBody = {
      ...builderInstance.instance.schema,
      title: formConfig.title,
      settings: {
        ...builderInstance.instance.schema.settings,
        isPublished: false,
        editedBy: userId,
        isDefault: false,
      },
      name,
      path,
    };

    removeCustomDataPage(reqBody);

    if (!currentFormId) {
      return createForm(addCustomNavButtonSettings(reqBody));
    }

    return editForm({
      ...addCustomNavButtonSettings(reqBody),
      settings: {
        ...reqBody.settings,
        ...formConfig.settings,
        editedBy: userId,
      },
    });
  }, [
    builderInstance,
    currentFormId,
    formConfig,
    createForm,
    editForm,
    isTemplateValid,
    setIsSubmitted,
  ]);

  useEffect(() => {
    if (builderRootEl && formConfig && !builderInstance) {
      const builder = new FormBuilder(
        builderRootEl,
        { ...formConfig },
        formOptions || {},
      );

      builder.ready.then(() => {
        updateMenu();
        builder.instance.triggerRedraw();
      });

      setBuilderInstance(builder);
    }
  }, [
    builderRootEl,
    builderInstance,
    formConfig,
    formOptions,
    setBuilderInstance
  ]);

  return (
    <>
      <style>{cssStyling}</style>
      <Grid container direction="column" alignContent="center">
        {!isLoading && formConfig ? (
          <div className="container-fluid">
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={5} md={4} lg={3} wrap="nowrap" container justify="space-between" alignItems="center">
                <FormControl margin="normal" fullWidth>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={translate.t('laTitle')}
                    value={formConfig.title}
                    onChange={handleTitleChange}
                    error={isSubmitted && !tplNameValidity}
                    required
                  />
                  <FormHelperText error margin="dense">
                    {isSubmitted && !tplNameValidity ? translate.t('laFormTemplateTitleInvalid') : ''}
                  </FormHelperText>
                </FormControl>
                {
                  latestVersion && formConfig && (
                    <TemplateUpdateIconBtns
                      updated={formConfig.settings.version === latestVersion}
                      formId={formConfig._id}
                      marginLeft={'20px'}
                    />
                  )
                }
              </Grid>
              <Grid item>
                <Button variant="text" onClick={cancelHandler}>
                  {translate.t('laCancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {translate.t('laSave')}
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          undefined
        )}
        {isSubmitted && !tplValidityStatus ? (
          <Grid item container xs={12}>
            <Grid item xs={12} className={classes.extraSpacing}>
              <div className="container-fluid">
                <div className="alert alert-danger" role="alert">
                  {translate.t('laTemplateMissingRequiredFields')}
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          undefined
        )}
        <Grid item container xs={12} alignContent="center" justify="center">
          {isLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <div className="container-fluid">
              <div ref={rootElCallbackRef} />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const enhance = compose<Props, {}>(withStyles(styles));

export default enhance(EditFormTemplateDialog);
