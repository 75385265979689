import * as React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

type Props = {
  name: string
};

export const Header: React.FunctionComponent<Props> = (props) => {
  return(
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography variant="h6" color="inherit">
          {props.name}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
