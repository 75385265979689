import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import Internal from '@/app/layouts/Internal/Internal';
import External from '@/app/layouts/External/External';
import Isolated from '@/app/layouts/Isolated/Isolated';
import store from '@/app/redux/store';
import { Provider } from 'react-redux';
import { HOMEPAGE } from '@/app/utils/helper';
import UserActivityLogging from '@/app/components/UserActivityLogging/UserActivityLogging';

const hist = createBrowserHistory();
hist.listen(() => {
  window.dispatchEvent(new Event('routeChange'));
});

const Routes = () => {
  return (
    <>
      <UserActivityLogging />
      <Router history={hist}>
        <Switch>
          <Route path={`${HOMEPAGE}/internal`} component={Internal} />
          <Provider store={store}>
            <>
              <Route path={`${HOMEPAGE}/external`} component={External} />
              <Route path={`${HOMEPAGE}/isolated`} component={Isolated} />
            </>
          </Provider>
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
