import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const companyInfoStyle: StyleRulesCallback = (theme: Theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      '& img': {
        maxWidth: 'calc(50vw - 60px)',
        height: 'auto !important'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& img': {
        maxWidth: 'calc(100vw - 140px)',
        height: 'auto !important'
      }
    }
  }
});

export default companyInfoStyle;