import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import JobSplit from '@/app/pages/JobSplit/JobSplit';
import TrainingSplit from '@/app/pages/TrainingSplit/TrainingSplit';
import EmploymentForms from '@/app/pages/EmploymentForms/EmploymentForms';
import EmploymentFormDetails from '@/app/pages/EmploymentForms/EmploymentFormDetails/EmploymentFormDetails';
import NewEmployment from '@/app/pages/EmploymentForms/NewEmployment/NewEmployment';
import { HOMEPAGE } from '@/app/utils/helper';

const GLOBAL: any = window;

const IsolatedRoutes = () => {
  const isNewEmpFormsFeatureEnabled = () => {
    try {
      if (!GLOBAL.parent.__env) {
        return false;
      }
    
      return GLOBAL.parent.__env.REACT_APP_IS_NEW_DEPLOYMENT_TYPE === 'yes'
        && GLOBAL.parent.__env.REACT_APP_ENABLE_EMPLOYMENT_TEMPLATES === 'yes';
    } catch {
      return false;
    }
  };

  return (
    <Switch>
      <Route exact path={`${HOMEPAGE}/isolated/jobs`} component={JobSplit} />
      <Route exact path={`${HOMEPAGE}/isolated/jobs/:jobId`} component={JobSplit} />
      <Route exact path={`${HOMEPAGE}/isolated/trainings`} component={TrainingSplit} />
      <Route exact path={`${HOMEPAGE}/isolated/trainings/:trainingId/register`} component={TrainingSplit} />
      { isNewEmpFormsFeatureEnabled() ? (
        <>
          <Route exact path={`${HOMEPAGE}/isolated/form/employment/new`} component={NewEmployment} />
          <Route exact path={`${HOMEPAGE}/isolated/form/employment/new/:pendingEmp`} component={NewEmployment} />
          <Route exact path={`${HOMEPAGE}/isolated/form/employment/new/:pendingEmp/:selectedEmp`} component={NewEmployment} />
          <Route exact path={`${HOMEPAGE}/isolated/form/builder/employment`} component={EmploymentForms} />
          <Route exact path={`${HOMEPAGE}/isolated/form/builder/employment/:formId`} component={EmploymentFormDetails} />
        </>
      ) : undefined}
    </Switch>
  );
};

export default IsolatedRoutes;
