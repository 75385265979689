import { Typography, withStyles, WithStyles } from '@material-ui/core';
import { sanitize } from 'dompurify';
import * as React from 'react';

import trainingDetailsStyle from './trainingDetailsStyle';

type OwnProps = {
  title: string;
  description: string;
};

type Props = OwnProps & WithStyles<typeof trainingDetailsStyle>;

const TrainingDetails: React.FunctionComponent<Props> = ({ classes, title, description }) => {
  return (
    <>
      <div className={classes.title}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      </div>

      <Typography
        gutterBottom
        className={classes.description}
        dangerouslySetInnerHTML={{__html: sanitize(description)}}
      />
    </>
  );
};

export default withStyles(trainingDetailsStyle)(TrainingDetails);
