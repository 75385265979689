import * as React from 'react';
import { Grid, Divider, Hidden } from '@material-ui/core';
import CompanyInfo from '@/app/components/CompanyInfo/CompanyInfo';
import JobsList from '@/app/components/Jobs/JobsList/JobsList';
import { container, divider } from '@/app/jss/common';

const Jobs = (props: any) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={container}
      >
        <CompanyInfo />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={container}
      >
        <Hidden mdUp>
          <Divider style={divider} />
        </Hidden>

        <JobsList {...props} />
      </Grid>
    </Grid>
  );
};

export default Jobs;