import { MenuItem, Select, Typography } from '@material-ui/core';
import React, { ChangeEvent, useCallback } from 'react';

import translate from '@/app/utils/translate';
import { GridTemplateListItem } from '../../types';

type Props = {
  selectedTemplateId: string;
  formTemplates: GridTemplateListItem[];
  onFormTemplateChange: (id: string) => void;
};

export const FormTemplateSelection = ({
  formTemplates,
  selectedTemplateId,
  onFormTemplateChange,
}: Props) => {

  const handleTemplateChange = useCallback((
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    onFormTemplateChange(event.target.value);
  }, [onFormTemplateChange]);

  return (
    <>
      { !formTemplates || !formTemplates.length ? (
        <Typography inline={true}>
          {translate.t('laNoTemplatesAvailable')}
        </Typography>
      ) : (
        <Select
          value={selectedTemplateId}
          onChange={handleTemplateChange}
        >
          {formTemplates ? formTemplates.map(formTemplate => (
            <MenuItem key={formTemplate._id} value={formTemplate._id}>
              {formTemplate.title}
            </MenuItem>
          )) : undefined}
        </Select>
      )}
    </>
  );
};
