import { Grid, Divider, Hidden } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';

import CompanyInfo from '@/app/components/CompanyInfo/CompanyInfo';
import TrainingsList from '@/app/components/PublicTrainings/TrainingsList/TrainingsList';
import { container, divider } from '@/app/jss/common';
import { HOMEPAGE } from '@/app/utils/helper';

const Trainings: React.FC<RouteComponentProps> = props => {
  const handleRegisterForTraining = (trainingId: string) => {
    props.history.push(`${HOMEPAGE}/external/trainings/${trainingId}/register`);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} style={container}>
        <CompanyInfo />
      </Grid>

      <Grid item xs={12} sm={12} md={6} style={container}>
        <Hidden mdUp>
          <Divider style={divider} />
        </Hidden>

        <TrainingsList onRegisterForTraining={handleRegisterForTraining} />
      </Grid>
    </Grid>
  );
};

export default Trainings;
