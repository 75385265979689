import { StyleRulesCallback } from '@material-ui/core';

const trainingSplitStyle: StyleRulesCallback = (theme) => ({
  gridDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: theme.spacing.unit,
  }
});

export default trainingSplitStyle;
