import { transition } from '@/app/jss/common';
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const externalStyle: StyleRulesCallback = () => ({
  wrapper: {
    height: 'calc(100vh - 110px)',
    overflow: 'auto',
    padding: '15px',
    ...transition,
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    padding: '15px',
  },
  container: {
    minHeight: 'calc(100vh - 140px)',
    padding: '30px',
  },
});

export default externalStyle;
