import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const jobDetailStyle: StyleRulesCallback = () => {
  return {
    description: {
      overflowX: 'hidden',
    },
  };
};

export default jobDetailStyle;
