import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import accessCodeFormStyle from './accessCodeFormStyle';
import { throwError } from '@/app/redux/error';
import { getFormValues, isInvalid, isPristine, isSubmitting } from 'redux-form';
import { viewDocument } from '@/app/redux/documentApproval';
import { getForm } from '../../Form/Form';

const validate = (values: AccessCode) => {
  let errors: {[k: string]: any} = {};
  const requiredFields = [
    'accessCode',
  ];

  requiredFields.forEach(field => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  if (values.accessCode && values.accessCode.length > 10) {
    errors.accessCode = `${translate.t('laErrorMaxTextLen1')} 10`;
  }

  return errors;
};

const FORM_NAME = 'accessCodeForm';

interface MapStateToProps {
  currentValues: AccessCode;
  entityId: number;
  invalid: Function;
  pristine: Function;
  submitting: Function;
}

interface MapDispatchToProps {
  throwError: Function;
  viewDocument: Function;
}

interface OwnProps {
  invalid: any;
  pristine: any;
  submitting: any;
}

type PropsType = OwnProps & WithStyles<typeof accessCodeFormStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = () => {
  return {
    accessCode: '',
  };
};

type AccessCode = {
  accessCode: string;
};

type StateType = {
  formData: AccessCode;
};

class AccessCodeForm extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
  };
  Form: any;

  constructor(props: PropsType) {
    super(props);
    this.Form = getForm(FORM_NAME, validate);
  }

  handleView = () => {
    const { accessCode } = this.props.currentValues;
    const { entityId } = this.props;

    this.props.viewDocument({ accessCode, entityId });
  };

  render() {
    const { classes, invalid, pristine, submitting } = this.props;
    const { formData } = this.state;
    const { Form } = this;
    const disabled = invalid(FORM_NAME) || pristine(FORM_NAME) || submitting(FORM_NAME);
    const inputs = [
      {
        type: INPUT_TYPE.TEXT,
        code: 'accessCode',
        name: 'accessCode',
        label: translate.t('label_access_code'),
        order: 1,
        required: true,
      },
    ];

    return (
      <Card>
        <CardContent>
          <Typography component="h1" variant="h6">
            {translate.t('text_enter_access_code')}
          </Typography>
          <Form formData={formData}>
            <FormFields inputs={inputs} />
          </Form>
        </CardContent>
        <CardActions className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleView}
            disabled={disabled}
          >
            {translate.t('label_view_document')}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { documentApproval: docApp } = state;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    entityId: docApp.get('entityId'),
    invalid: (formName: string) => isInvalid(formName)(state),
    pristine: (formName: string) => isPristine(formName)(state),
    submitting: (formName: string) => isSubmitting(formName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  viewDocument: (payload: any) => dispatch<any>(viewDocument(payload)),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(accessCodeFormStyle, {withTheme: true}),
);

export default enhance(AccessCodeForm);
