import { Builders, ComponentSchema, Utils } from 'formiojs';
import {
  FormioComponent,
  FormioComponentGroup,
  FormioEditComponentFlags,
  FormioFormBuilderOptions,
} from '../../types';

const WizardBuilder = Builders.getBuilder('wizard');
export class CustomWizardBuilder extends WizardBuilder {
  constructor(
    element: HTMLElement,
    options: FormioFormBuilderOptions
  ) {
    super(element, options);

    this.on('addComponent', (
      component: ComponentSchema,
      _parent: typeof WizardBuilder,
      _path: string,
      _index: number,
      _isNew: true,
    ) => {
      this.updateGroups(true, component);
    });

    this.on('removeComponent', (
      component: ComponentSchema,
      _parent: typeof WizardBuilder,
      _path: string,
      _index: number,
    ) => {
      this.updateGroups(true, component);
    });
  }

  render() {
    this.updateGroups(false);
    return super.render();
  }

  updateGroups(refresh: boolean, component?: ComponentSchema) {
    const restricted = [
      ...Object.values(Utils.flattenComponents(this.form.components, true)),
    ].filter((c: FormioComponent) => c && c.builder && (c.builder.onlyOnce || false));

    const restrictedIds = restricted.map((c: FormioComponent) => {
      if (!c || !c.builder) {
        return false;
      }

      return c.properties && c.properties.identifier
        ? c.properties.identifier
        : c.key;
    });

    this.groups = Object.keys(this.groups).reduce((acc: any, groupKey: string) => {
      const additional = [
        ...Object.values(this.groups[groupKey].components),
      ].filter((groupComp: FormioComponent) => {
        const componentIdentifier = groupComp.schema && groupComp.schema.properties
          ? groupComp.schema.properties.identifier || groupComp.key || groupComp.schema.type
          : undefined;
        const isRestricted = restrictedIds.includes(componentIdentifier);
        const isOnce = groupComp.schema && groupComp.schema.builder 
          ? groupComp.schema.builder.onlyOnce
          : false;
        
        const componentSchemaComponents = groupComp && groupComp.schema && groupComp.schema.components
          ? [...groupComp.schema.components]
          : [];

        const foundComponent = componentSchemaComponents.find(c => {
          const schemaIdentifier = c && c.properties
            ? c.properties.identifier || c.key
            : undefined;

          return schemaIdentifier
            ? restrictedIds.includes(schemaIdentifier)
            : false;
        });

        return isRestricted && isOnce && !!foundComponent;
      }).map((c: FormioComponent) => c && c.properties
        ? (c.properties.identifier || c.key)
        : undefined
      );

      const alreadyUsedComponentIds = restrictedIds.concat(additional);

      return ({
        ...acc,
        [groupKey]: {
          ...this.groups[groupKey],
          default: this.isGroupOpen(this.groups[groupKey], component),
          componentOrder: Object.keys(this.groups[groupKey].components).filter(compId => {
            return !alreadyUsedComponentIds.includes(compId);
          }).sort(),
        }
      });
    }, {});

    if (refresh) {
      this.triggerRedraw();
    }
  }

  isGroupOpen(group: FormioComponentGroup, component: ComponentSchema) {
    if (!component) {
      return !!(group && group.default);
    }

    return (
      [...this.refs['sidebar-group']].find((g) => {
        const groupId = g.getAttribute('id').slice('group-'.length);
        return groupId === group.key;
      }).classList.contains('show') || false
    );
  }

  editComponent(
    component: FormioComponent,
    parent: HTMLElement,
    isNew: boolean,
    isJsonEdit: boolean | null,
    original: FormioComponent | null,
    flags: FormioEditComponentFlags = {}
  ) {
    if (!component) {
      return;
    }

    if (component.builder && component.builder.disableEdit) {
      return;
    }

    if (isNew && component.builder && !component.builder.initialEdit) {
      return;
    }

    super.editComponent(component, parent, isNew, isJsonEdit, original, flags);
  }
}
